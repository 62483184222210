import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  static objectFromPath(path: any, obj: any) {
    return path.reduce((prev: any, curr: any) => {
      return prev ? prev[curr] : null
    }, obj || self)
  }

  static pathifyObject(object: any) {
    if (!object) {
      alert('cannot pathify non object')
      return
    }

    this.addPathToObjectArrayItems(object)
    this.addPathToObjectObjects(object)

    return object
  }

  static addPathToObjectArrayItems(object: any) {
    if (!object) {
      object = { some: [{ thing: 'such object' }], else: [{ more: 'stuff' }] }
    }

    const objectPath = object.path || [object.key]
    const arrays = this.getObjectArrays(object)
    arrays.forEach((arrayInfo) => {
      const arrayKey = arrayInfo.key
      arrayInfo.array.forEach((item, index) => {
        // if the item is an object, it can hold a path
        if (this.isObject(item)) {
          item.path = [...objectPath, arrayKey, index]
          // repeate for children
          this.addPathToObjectArrayItems(item)
          this.addPathToObjectObjects(item)
        }
      })
    })
    // debug.info('added path, now', object)
  }

  static addPathToObjectObjects(object: any) {
    if (!object) {
      object = { some: { thing: 'cool' } }
    }

    const objectPath = object.path || [object.key]
    const objectInfos = this.getObjectObjects(object)
    objectInfos.forEach((objectInfo: any) => {
      const objectKey = objectInfo.key
      objectInfo.object.path = [...objectPath, objectKey]
      // repeat for children
      this.addPathToObjectArrayItems(objectInfo.object)
      this.addPathToObjectObjects(objectInfo.object)
    })
  }

  static getObjectObjects(object: any) {
    if (!object) {
      alert('no object for object objects')
    }

    const objectObjects: any = []
    Object.keys(object).forEach((key) => {
      const item = object[key]
      if (this.isObject(item)) {
        objectObjects.push({ key: key, object: item })
      }
    })
    return objectObjects
  }

  static getObjectArrays(object: any): { key: string; array: any[] }[] {
    if (!object) {
      alert('no object!')
    }

    const objectArrays: any = []

    Object.keys(object).forEach((key) => {
      const item = object[key]
      if (this.isArray(item)) {
        objectArrays.push({ key: key, array: item })
      }
    })
    return objectArrays
  }

  // utils
  static isString(value: any) {
    return typeof value === 'string' || value instanceof String
  }

  static isObject(value: any) {
    return value && typeof value === 'object' && value.constructor === Object
  }

  static isNumber(value: any) {
    return typeof value === 'number' && isFinite(value)
  }

  static isArray(value: any) {
    return value && typeof value === 'object' && value.constructor === Array
  }

  static isFunction(value: any) {
    return typeof value === 'function'
  }

  static isNull(value: any) {
    return value === null
  }

  static isUndefined(value: any) {
    return typeof value === 'undefined'
  }

  static isBoolean(value: any) {
    return typeof value === 'boolean'
  }

  static isError(value: any) {
    return value instanceof Error && typeof value.message !== 'undefined'
  }

  static isDate(value: any) {
    return value instanceof Date
  }

  static isSymbol(value: any) {
    return typeof value === 'symbol'
  }

  static isClass(value: any) {
    if (!value || !value.constructor) {
      return
    }
    return value.constructor.toString().startsWith('class ')
  }

  // util, not a good place here, move up
  static unCamelCase(str: string) {
    if (!str) {
      return 'nothing to un camel'
    }
    str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')
    str = str.toLowerCase()
    return str
  }

  static lightOrDark(color: any) {
    if (!color) {
      return 'dark'
    }

    // Variables for red, green, blue values
    let r
    let g
    let b
    let hsp

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      // eslint-disable-next-line no-bitwise
      r = color >> 16
      // eslint-disable-next-line no-bitwise
      g = (color >> 8) & 255
      // eslint-disable-next-line no-bitwise
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return 'light'
    } else {
      return 'dark'
    }
  }
}

// svg utils

export const smoothing = 0.3

export interface LineProperties {
  length: number
  angle: number
}

export function line(pointA: number[], pointB: number[]): LineProperties {
  const lengthX = pointB[0] - pointA[0]
  const lengthY = pointB[1] - pointA[1]
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  }
}

export function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

export function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ')

  return d
}

/**
 * Calcuates the control point position given current, previous and next point
 *
 * @param current (array) [x, y]: current point coordinates
 * @param previous previous (array) [x, y]: previous point coordinates
 * @param next next (array) [x, y]: next point coordinates
 * @param reverse reverse (boolean, optional): sets the direction
 */
export function controlPoint(current: [number, number], previous: [number, number], next: [number, number], reverse?: boolean): [number, number] {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current
  const n = next || current

  // Properties of the opposed-line
  const o = line(p, n)

  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0)
  const length = o.length * smoothing

  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length
  const y = current[1] + Math.sin(angle) * length
  return [x, y]
}

/**
 * Create the bezier curve command
 *
 * @param point (array) [x,y]: current point coordinates
 * @param i (integer): index of 'point' in the array 'a'
 * @param a (array): complete array of points coordinates
 */
export function bezierCommand(point: [number, number], i: number, a: [number, number][]): string {
  // start control point
  const cps = controlPoint(a[i - 1], a[i - 2], point)

  // end control point
  const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
  return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
}

/**
 *
 *  Render the svg <path> element
 *
 * @param points points (array): points coordinates
 * @param command command (function)
 */
export function svgPath(points: [number, number][]): string {
  // build the d attributes by looping over the points
  const d = points.reduce((acc, point, i, a) => (i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${bezierCommand(point, i, a)}`), '')
  return d
}

export class SVGUtils {
  /**
   * calculates the length and angle of a line between 2 given points
   */
  static line(pointA: [number, number], pointB: [number, number]): LineProperties {
    const lengthX = pointB[0] - pointA[0]
    const lengthY = pointB[1] - pointA[1]
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    }
  }

  /**
   * Create the bezier curve command
   *
   * @param point (array) [x,y]: current point coordinates
   * @param i (integer): index of 'point' in the array 'a'
   * @param a (array): complete array of points coordinates
   */
  static bezierCommand(point: [number, number], i: any, a: any): string {
    // start control point
    const cps = this.controlPoint(a[i - 1], a[i - 2], point)

    // end control point
    const cpe = this.controlPoint(point, a[i - 1], a[i + 1], true)
    return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
  }

  /**
   *
   *  Render the svg <path> element
   *
   * @param points points (array): points coordinates
   * @param command command (function)
   */
  static svgPath(points: any): string {
    // build the d attributes by looping over the points
    const d = points.reduce((acc: any, point: any, i: any, a: any) => (i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${this.bezierCommand(point, i, a)}`), '')
    return d
  }

  /**
   * Calcuates the control point position given current, previous and next point
   *
   * @param current (array) [x, y]: current point coordinates
   * @param previous previous (array) [x, y]: previous point coordinates
   * @param next next (array) [x, y]: next point coordinates
   * @param reverse reverse (boolean, optional): sets the direction
   */
  static controlPoint(current: [number, number], previous: [number, number], next: [number, number], reverse?: boolean): [number, number] {
    // When 'current' is the first or last point of the array
    // 'previous' or 'next' don't exist.
    // Replace with 'current'
    const p = previous || current
    const n = next || current

    // Properties of the opposed-line
    const o = line(p, n)

    // If is end-control-point, add PI to the angle to go backward
    const angle = o.angle + (reverse ? Math.PI : 0)
    const length = o.length * smoothing

    // The control point position is relative to the current point
    const x = current[0] + Math.cos(angle) * length
    const y = current[1] + Math.sin(angle) * length
    return [x, y]
  }
}

export function versionCompare(v1: any, v2: any, options = { lexicographical: false, zeroExtend: false }) {
  var lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend,
    v1parts = v1.split('.'),
    v2parts = v2.split('.')

  function isValidPart(x: any) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0')
    while (v2parts.length < v1parts.length) v2parts.push('0')
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number)
    v2parts = v2parts.map(Number)
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1
    }

    if (v1parts[i] == v2parts[i]) {
      continue
    } else if (v1parts[i] > v2parts[i]) {
      return 1
    } else {
      return -1
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1
  }

  return 0
}

import { FormControl, UntypedFormControl } from '@angular/forms'
import { DateRange } from '@angular/material/datepicker'
import { Observable, Subscription } from 'rxjs'
import { ContentfulPlanVariant, ContentfulService } from './billing/contentful-interfaces'

export type ExpansionStates = 'collapsed' | 'expanded'

export interface MultiStatusOverlayContent {
  status: { icon: string; message: string }[]
  closeable?: boolean
  extraInfo?: string
  auto_close?: boolean
  title?: string
}

export class Overlay {
  message: string

  icon: string
}

export interface KeycloakData {
  session?: SessionData
  access_token?: KeycloakJWT
  refresh_token?: KeycloakJWT
  userDetails?: UserDetailsResponse
}

export type ResponseTuple = [Observable<LastTrafficlightsResponse>, Observable<MetadataResponse>]

export class LoginResponse {
  data: SessionData | MiddlewareError
}

export interface RefreshData {
  access_token: string
  expires_in: number
  id_token: string
  'not-before-policy': number
  refresh_expires_in: number
  refresh_token: string
  scope: 'openid profile email'
  session_state: string
  token_type: 'bearer'
}

export interface RefreshResponse {
  status_code: number
  data: RefreshData
}

export class MiddlewareError {
  error: string

  error_description: string
}

export class SessionData {
  access_token: string | null
  refresh_token: string | null
}

export class SSOSessionData {
  access_token: string | null
  refresh_token: string | null
}

export type UserAttributeValues =
  | 'PKIready'
  | 'locale'
  | 'country'
  | 'roles'
  | 'feature_flag_mms'
  | 'feature_flag_emma'
  | 'feature_flag_dms'
  | 'feature_flag_emob'
  | 'emma_simulator'
  | 'AdminService'

export type AdminServiceAttributeValues = 'uuid' | 'dt' | 'fw' | 'true'

export interface UserDetailsResponse {
  data: {
    attributes?: {
      country: [string]
      krn: [string]
      locale?: [string]
      path: [string]
      termsAndConditions: [string]
      PKIready?: ('true' | 'false')[]
      roles?: string[]
      feature_flag_mms?: string[]
      feature_flag_emma?: string[]
      feature_flag_dms?: string[]
      feature_flag_emob?: string[]
      emma_simulator?: string[]
      AdminService?: AdminServiceAttributeValues[]
    }
    created: string
    email: string
    id: string
    disableableCredentialTypes: string[]
    emailVerified: boolean
    firstName: string
    keycloakUserId: string
    krn: string
    lastName: string
    path: [string]
    tenantID: string
    updated: string
    userId: string
    username: string
  }
}

export class UserInvitationResponse {
  data: {
    existingUser: boolean
    emailSent: boolean
    registrationLink: string
  }
}

export class DeviceListResponse {
  content: DeviceInfo[]

  totalElements: number
}

export class Device {
  endpointId: string

  createdDate: string

  metadataUpdatedDate: string

  filters?: string[]

  metadata: DeviceMetaData

  // set by app
  filteredIn?: boolean

  filteredOut?: boolean

  // set by timeseries quries
  tsd?: TSD
}

export class DeviceTypeInformation {
  article: string

  business_unit?: string

  category: string

  description: string

  device_type: string

  gateway?: boolean

  virtual?: boolean

  id: number

  jws: boolean

  services: ServiceIds[]
}

export interface TSD {
  con_logs?: LogEntry[]
}

export type endpointId = string

type LastTrafficLightResponse = {
  con_trafficlights: {
    timestamp: string
    values: {
      con_TrafficLightColor: 0 | 1 | 2
      con_TrafficLightMessage: string
    }
  }[] // array of length 1
}

export type LastTrafficlightsResponse = Record<string, LastTrafficLightResponse>

export interface MetadataResponse {
  totalElements: number
  content: Content[]
}

interface Content {
  endpointId: string
  createdDate: string
  metadataUpdatedDate: string
  appVersion: AppVersion
  metadata: Metadata
}

export interface Metadata {
  owner?: string
  address?: string
  city?: string
  latitude?: number
  con_DeviceType?: string
  con_ConnectorVersion?: string // semver
  con_connected_ts?: string
  networkMask?: string
  deviceName: string
  uuid: string
  con_ArticleNumber?: string
  mac?: string
  fwVersion_name?: string
  con_HardwareVersion?: string
  serial?: string
  externalIPAddress?: string
  con_FirmwareVersion?: string
  internalIPAddress?: string
  model?: string
  fwVersion?: string
  con_SerialNumber?: string
  con_connected?: boolean
  customer?: string
  longitude?: number
  createdDate_formatted?: string
  con_FirmwareVersion_formatted?: string
  uuid_formatted?: string
  trafficLightNumber?: number
  con_connected_formatted?: string
  trafficLightMessage_formatted?: string
  firmwareUpdates?: any[]
  con_HardwareVersion_formatted?: string
  trafficLightMessage?: string
  comment_formatted?: string
  con_connected_ts_formatted?: string
  con_SerialNumber_formatted?: string
  con_DeviceType_formatted?: string
  trafficLight?: string
  lng?: number
  comment?: string
  lat?: number
  tags?: string[]
}

interface FirmwareUpdateX {
  transaction_id: string
  firmware_id: string
  kaa: Kaa
  firmware_version: string
  device_uuid: string
  timestamp: number
}

interface Kaa {
  active: string
  check_download: string
  check_install: string
  compatibility: Compatibility | string
  download: Compatibility | string
}

interface Compatibility {
  status_code: number
  message: string
}

interface AppVersion {
  name: string
  registeredDate: string
}

export interface LogEntry {
  timestamp: string
  values: {
    con_LogLevel: 1
    con_LogMessage: 'Done waiting: Timer TrafficLight'
    con_LogTags: 'Arp.Services.ProfiCloudV3.Internal.CustomTimer'
  }
  // interal
  filteredIn: boolean
  filteredOut: boolean
}

export type LogsResponse = Record<string, { con_logs: Conlog[] }>[]

export interface Conlog {
  timestamp: string
  values: LogValues
}

interface LogValues {
  con_LogLevel: number
  con_LogMessage: string
  con_LogTags: string
}

export type FirmwareUpdateStatuses = 'active' | 'compatibility' | 'download' | 'check_download' | 'check_install'

export type FirmwareUpdateStatus = {
  compatibility?: FirmwareUpdateResults
  download?: FirmwareUpdateResults
  check_download?: FirmwareUpdateResults
  check_install?: FirmwareUpdateResults
  active?: boolean
}

export type FirmwareUpdateResults = 'running' | 'success' | 'error'

export type FirmwareUpdateStageLabel = { name: string; key: FirmwareUpdateStatuses }

export type FirmwareResult = Record<FirmwareUpdateStatuses, Record<FirmwareUpdateResults, FirmwareStatus> | boolean>

export type FirmwareStatusCodes = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13

export type FirmwareStatus = {
  status_code: FirmwareStatusCodes
  message: string
  progress?: number
}

export interface FirmwareUpdate {
  device_id: string
  firmware_id: string
  firmware_version: string
  firmware_update_status: Record<FirmwareUpdateStatuses, FirmwareStatus | boolean>
  timestamp: number
  transaction_id: string
  initialRequest?: string
  info_message?: string
}

export class DeviceInfo extends Device {
  appVersion: {
    name: string
    registeredDate: string
  }

  // internal
  expanded?: boolean

  expansionState?: ExpansionStates

  availableFirmware?: FirmwareV2[]

  newestFirmware?: FirmwareV2

  firmwareUpdateAllowed?: boolean

  firmwaredUpdateAllowState?: { allowed: boolean; reason: string }

  logRange?: {
    start: Date
    end: Date
  }

  customLogs?: {
    range?: {
      start: Date
      last_start?: Date
      end: Date
      last_end?: Date
    }
    query?: string
    logs?: Conlog[]
  }

  availableMetrics?: string[]

  usedMetrics?: string[]

  // set by firmware service
  firmwareUpdates?: FirmwareUpdate[]

  // the automatic firmware update is deactivated by default
  automaticFirmwareUpdate? = false
}
export type NotificationType = 'info' | 'warning' | 'error'

export interface DeviceNotification {
  notficationType: NotificationType
  message: string
}

export interface SDKMetaData {
  // con_connected: boolean // set by kaa periodically
  con_ArticleNumber?: string
  con_DeviceType?: string
  con_ConnectorVersion?: string
  con_FirmwareVersion?: string
  con_HardwareVersion?: string
  con_SerialNumber?: string
  con_LogLevel?: number
  con_LogTags?: string[]
  con_LogMessage?: string
  con_DeviceLED?: string
  con_connected?: boolean | 'online'
  con_connected_ts?: string
  // child/parent relationship
  child?: boolean
  parent_endpointID?: string
  children_endpointIDs?: { endpointID: string }[]
}

export interface UserMetaData {
  deviceName: string
  automatic_update?: boolean
  lng?: number
  lat?: number
  address?: string
  city?: string
  country?: string
  comment?: string
  uuid?: string
  tags?: string[]
  dms_basic_add_on?: boolean
  last_application?: string
}

export type TrafficLightNumbers = 0 | 1 | 2

export interface ProcessedMetaData {
  trafficLightNumber?: TrafficLightNumbers
  trafficLightMessage?: string
  firmwareNotification?: DeviceNotification
}

export interface WhishlistMetaData {
  connected?: boolean
  lastConnectedAt?: string
}

export interface FormattedValues {
  con_connected_formatted?: string
  trafficLightMessage_formatted?: string
  con_DeviceType_formatted?: string
  createdDate_formatted?: string
  con_connected_ts_formatted?: string
  con_SerialNumber_formatted?: string
  con_FirmwareVersion_formatted?: string
  con_HardwareVersion_formatted?: string
  uuid_formatted?: string
  comment_formatted?: string
}

export type DeviceMetaData = SDKMetaData & WhishlistMetaData & UserMetaData & ProcessedMetaData & FormattedValues

export class KaaData {
  devices: DeviceInfo[] | undefined

  devicesWithErrorCount: number
}

export class CreateDevicePostData {
  uuid?: string

  organizationId: string

  deviceName: string

  comment?: string

  lat?: number

  lng?: number

  address?: string

  tags?: string[]
}

export type DeviceSortItemProperty = 'uuid' | 'deviceName' | 'trafficLightNumber' | 'con_DeviceType'
type DeviceSortItemLabel =
  | 'UUID'
  | 'Device Name'
  | 'Status'
  | 'Device Type'
  | 'DEVICE.SORT_OPTION_STATUS'
  | 'DEVICE.SORT_OPTION_DEVICE_NAME'
  | 'DEVICE.SORT_OPTION_DEVICE_TYPE'

type UserSortItemProperty = 'name'
type UserSortItemLabel = 'USER_MANAGEMENT_SERVICE.SORT_OPTION_NAME'

type DeviceGroupItemProperty = 'name' | 'trafficLightNumber'
type DeviceGroupSortLabel = 'DEVICE_GROUP.SORT_OPTION_STATUS' | 'DEVICE_GROUP.SORT_OPTION_DEVICE_NAME'

type UserRoleSortItemProperty = 'name'
type UserRoleSortItemLabel = 'USER_MANAGEMENT_SERVICE.ROLE_SORT_OPTION_NAME'

type UserAssignmentSortItemProperty = 'firstName' | 'lastName' | 'role'
type UserAssignmentSortItemLabel = 'First Name' | 'Last Name' | 'Role'

export type SortOrder = 'asc' | 'desc'

export interface SortOption {
  property: DeviceSortItemProperty | UserSortItemProperty | UserRoleSortItemProperty | DeviceGroupItemProperty | UserAssignmentSortItemProperty
  label: DeviceSortItemLabel | UserSortItemLabel | UserRoleSortItemLabel | DeviceGroupSortLabel | UserAssignmentSortItemLabel
  shortLabel: string
  order: SortOrder
  defaultOrder?: SortOrder
}

export interface DropDownItem<T = string> {
  label: string
  icon?: string
  id?: string
  disabled?: boolean
  hidden?: boolean
  action?: { context: any; method: (input: any) => any; input: any }
  item?: T
  subItems?: DropDownItem<T>[]
  subItemsVisible?: boolean
}

export interface KeycloakJWT {
  activeOrganization: string
  jti: string
  exp: number
  nbf: number
  iat: number
  iss: string
  aud: string
  sub: string
  typ: string
  azp: string
  auth_time: number
  session_state: string
  acr: string
  'allowed-origins': string[]
  realm_access: RealmAccess
  resource_access: {}
  scope: string
  email_verified: boolean
  preferred_username: string
  locale: string
  email: string
}

export interface RealmAccess {
  roles: string[]
}

export interface HealthStatusTSD {
  con_trafficlights: ConTrafficlight[]
}

export interface ConTrafficlight {
  timestamp: string
  values: TrafficColorAndMessage
}

export interface TrafficColorAndMessage {
  con_TrafficLightColor: number
  con_TrafficLightMessage: string
}

export interface PCOverlayConfig {
  title: string
  imageUrl?: string
  imageSubtext?: string
  closeable?: boolean
}

export interface InformationBannerConfig {
  title: string
  message?: string
  buttonVisibility?: boolean
  buttonText?: string
  buttonLink?: string
  closeable?: boolean
  showInProduction: boolean | false
}

export interface Firmware {
  device_type: string
  firmware_id: string
  firmware_image: string
  firmware_version: string
  release_date: string
}

export interface FirmwareV2 extends Firmware {
  changelog: any
  device_type: string
  firmware_checksum: string
  firmware_id: string
  firmware_image: string
  firmware_size: number
  firmware_version: string // "2020.4"
  release_date: string // "01.01.2014"
  public: boolean
  update_information: string
}

export interface FirmwareV3 {
  changelog: any
  device_type: string
  id: string
  version: string
  semantic_version: string
  release_date: string
  public: boolean
  update_information: string
}

export interface AvailableFirmwareResponse {
  newer_version_available: boolean
  newest_firmware_id: string
  firmwares: FirmwareV2[]
}

export type ChartType = 'bar' | 'line' | 'load-duration-curve' | 'pie' | 'pareto' | 'heat-map' | 'table'
export type ComparisonType = 'consumer' | 'timePeriod' | 'rankedConsumer' | 'rankedTimePeriod'
export type WidgetMetric = 'power' | 'energy' | 'pressure'
export type WidgetMetricSelectOption = 'power' | 'energy' | 'pressure' // | 'mixed'
export type DataSourceUnit = 'power' | 'energy' | 'pressure'
export type WindowPeriods = '1s' | '1min' | '15min' | '1h' | '1d' | '1w' | '1m' | '1y'
export type QueryableNodeType = 'metric' | 'metering_point' | 'calculation' | 'aggregated_metric'
export type WidgetNodeType = 'metering_point' | 'metric' | 'calculation'
export type QueryableDataSourceTypes = MeteringPoint | Metric | Calculation | AggregatedMetric
export type QueryableTypedID = { id: string; type: QueryableNodeType; unitType?: BaseUnitTypeID; measurementType?: AcceptableMeasurementTypes }
export type UnitTypeKind = Record<BaseUnitTypeID, 'CONSUMPTION' | 'MEASUREMENT'>

// New Dashboards API
export interface TimePeriod {
  start: string | null // Date
  end: string | null // Date
  relativeRange: string | null
  visible: boolean
  appearance: {
    customColour?: string
    colourIndex?: number
    missingData?: boolean
  } | null
  orderNumber?: number
}

export type TypedQueryableID = {
  id: string
  unitType: BaseUnitTypeID
  measurementType?: AcceptableMeasurementTypes
}

export type DataSourceNode =
  | {
      type: 'metering_point'
      costConversion: CostsTypes | null
      value: TypedQueryableID
    }
  | {
      type: 'metric' | 'calculation'
      costConversion: CostsTypes | null
      value: string
    }

export interface DataSource {
  nodes: DataSourceNode[]
  name?: string
  visible: boolean
  appearance: {
    customColour?: string
    missingData?: boolean // set by app (not sure if this is the right place for this)
  } | null
  orderNumber?: number
}

export interface Widget {
  id?: string // Set on save
  description: string
  chartType: ChartType
  comparisonType: ComparisonType
  dataSources: DataSource[]
  timePeriods: TimePeriod[]
  window: WindowPeriods
  costType: CostsTypes | null
  outputUnits: { [key in BaseUnitTypeID]?: Unit }
  singleOutputUnit: Unit
  config: {
    // Properties specific to particular chart types
    fullLoad?: number // Load duration widgets
    partLoad?: number // Load duration widgets
    threshold?: number
    averageShown?: boolean
  } | null
  orderNumber?: number // Set on save
  version?: number // Set on save
  unitType: CompositeUnitType
  createdAt?: string // Date set on save
  updatedAt?: string // Date set on save

  // App
  preview?: boolean
}
export interface Dashboard {
  id?: string // On save
  accessUserIds: string[]
  name: string
  description: string
  orderNumber?: number // On save
  createdAt?: string // On save
  updatedAt?: string // On save
  widgets: Widget[]
}

export type SelectValue<T = string> = {
  key: string
  value: string
  visible?: boolean | (() => boolean)
  item?: T
}

export interface ProficloudInputConfig<SelectValueType = string> {
  // TODO: Just now having the same type for the FromControl<> and SelectValue<> will not
  // work unless the type is a string so we should separate these for clarity.
  control: FormControl<SelectValueType>
  inputId?: string
  key: string | number
  helpText?: string
  placeholder?: string
  icon?: string
  name?: string
  background?: string
  type:
    | 'text'
    | 'textSmall'
    | 'textArea'
    | 'password'
    | 'chips'
    | 'checkbox'
    | 'select'
    | 'timePeriod'
    | 'datePicker'
    | 'number'
    | 'autoArea' // defaults to input
    | 'hidden'
  value?: string | string[]
  disabled?: boolean
  multi?: boolean | (() => boolean)
  selectValues?: SelectValue<SelectValueType>[]
  dependsOn?: string | any[]
  maxLength?: number
  cssClass?: string
  maxTimeRange?: number
  colourIndex?: number
  hasPercentage?: boolean
  changed?: (value?: string | DateRange<Date> | Date | WindowPeriods | number, index?: number) => void
  checked?: boolean
  analyticsTag?: string
  noIcon?: boolean
  errorHint?: string
}

export type DateRangeStrings = {
  start: string
  end: string
}

export type ParetoDataItem = {
  [key: string]: string | Date | number
  uuid: string
  cumulative_percentage: number
  deviceName: string
  value: number
  timestamp: Date
  timestamp_formatted: string
  total: number
}

export type ParetoResponse = {
  data: ParetoDataItem[]
  total_consumption: number
}

export type PieDataItem = {
  data_source: string
  value: number
  label: string
}

export type PieResponse = PieDataItem[]

export type DataRouteItem = Record<string, string | Date | number>

export type StatisticsItem = {
  timestamp: string
  value: number
}

export interface StatisticsResponseSection {
  id: string
  start: string
  end: string
  value: StatisticsResponseItem
  unitType: CompositeUnitType
}

export interface StatisticsResponse {
  data: StatisticsResponseSection[]
  outputUnits: { [key in BaseUnitTypeID]?: Unit }
}

export type StatisticsKeys = 'min' | 'max' | 'mean' | 'std' | 'allTimeMin' | 'allTimeMax' | 'total' | 'avg'

export type StatisticsResponseItem = {
  min: StatisticsItem
  max: StatisticsItem
  mean: number
  std: number
  allTimeMin: StatisticsItem
  allTimeMax: StatisticsItem
  total: number
  avg: number
}

export type SelectSubItem<T = string> = {
  key: string
  value: string
  checked: boolean
  visible: boolean
  excluded?: boolean
  item?: T
  tagProperty?: string
  infoText?: string
}

export type SelectGroup<T = string> = {
  label: string
  checked: number
  values: SelectSubItem<T>[]
}

export type SelectGroupedItems<T = string> = Record<string, SelectGroup<T>>

export type SelectConfig<T = string> = {
  control: UntypedFormControl
  cssClass?: string
  dependsOn?: string
  hasDoneButton?: boolean
  hasAll?: boolean
  items: SelectGroupedItems<T> | SelectSubItem<T>[]
  key: string
  multi?: boolean
  placeholder?: string
  search?: boolean
  showValue?: boolean
  disabled?: boolean
  elementID?: string
  showInfoText?: boolean
  onChange?: (v: string | string[]) => void
}

export type RenderableData = {
  values: any[]
  total?: number
  unitType: CompositeUnitType
  unitMapping: { [key in BaseUnitTypeID]?: Unit }
}

export type Environments = 'dev' | 'staging' | 'production' | 'local'

export interface ImpulsePackagesResponseItem {
  id?: string
  name?: string
  bookableMultipleTimes: boolean
  chargingType: string
  contractTerms: {
    billingPeriod: string
    cancellationPeriod: string
    contractPeriod: string
    feePeriod: string
    trialPeriod: {
      quantity: number
      unit: string
    }
    description: string
    extras: any
    id: string
    maxNumberOfDevices: number
    name: string
  }
  pricing: {
    currency: string
    recurringFee: number
    setupFee: number
  }
  smartServiceId: string
}

export type ImpulsePackagesResponse = ImpulsePackagesResponseItem[]

export interface TsdPackagesResponseItem {
  bookableMultipleTimes: boolean
  chargingType: string
  contractTerms: {
    billingPeriod: string
    cancellationPeriod: string
    contractPeriod: string
    feePeriod: string
    trialPeriod: boolean
  }
  description: string
  extras: any
  id: string
  maxNumberOfMetrics: number
  name: string
  pricing: {
    currency: string
    recurringFee: number
    setupFee: number
  }
  recurringFeePerMonth: number
  smartServiceId: string
}

export type TsdPackagesResponse = TsdPackagesResponseItem[]

export type TsdMetric = {
  deviceName: string
  uuid: string
  metric: string
  device: DeviceInfo
}

export type ServiceSubscription = TsdSubscription | IaSubscription

export interface UpgradePreview {
  newSubscription: {
    net: number
    package: BookingPackage
    quantity: number
  }
  oldSubscription: {
    net: number
    package: BookingPackage
    quantity: number
  }
  subscription: ServiceSubscription
}
export interface SmartService {
  id: string
  name: string
  description: string
  bookablePackages: BookingPackage[]
}

export interface BookingPackage {
  id: string
  name: string
  description: string
  chargingType: string
  isBookableMultipleTimes: boolean
  pricing: {
    recurringFee: number
    setupFee: number
    currency: string
  }
  contractTerms: ContractTerms
  quantity: number
  extras: Map<string, string>
}

export interface SimplePackage {
  packageId: string
  quantity: number
  // coming up: free-text reference
  internalOrderId?: string
}

export interface ContractTerms {
  contractPeriod: TimeUnit
  cancellationPeriod: TimeUnit
  feePeriod: TimeUnit
  trialPeriod: TrialPeriod
  billingPeriod: TimeUnit
}

export interface TrialPeriod {
  quantity: number
  unit: TimeUnit
}

export type TimeUnit = [year, month, null]
export type year = number
export type month = number

export interface BillingAccount {
  readonly firstName: string
  readonly lastName: string
  readonly language: string
  readonly companyName: string
  readonly emailAddress: string
  readonly vatId: string
  readonly pxcCustomerId: string
  readonly address: BillingAddress
}

export interface BillingAddress {
  street: string | undefined
  houseNumber: string | undefined
  addressLine1: string
  addressLine2: string
  city: string
  postalCode: string
  country: AcceptableBillingCountryCodes
}

export interface ShoppingCart {
  service?: ContentfulService
  plan?: ContentfulPlanVariant
  quantity?: number
}

export interface SubscriptionResponse {
  readonly id: string
  readonly bookedPackageId: string
  readonly bookedPackage: BookingPackage
  readonly startDate: string
  readonly endDate: string
  readonly quantity: number
  readonly isInTrialPeriod: boolean
  readonly internalOrderId: string // user submitted reference
  readonly referenceCode: string //  contract reference, e.g. "LZPJT-HYLDX"
  readonly smartServiceId: ServiceIds
  readonly trialEndDate?: string
}

export interface IaSubscription extends SubscriptionResponse {
  readonly devicesUsed: string[]
  // added
  expanded?: boolean
  showCancelOption: boolean
}

// alex proxy sub
export interface TsdSubscription extends SubscriptionResponse {
  readonly smartServiceId: 'tsd'
  readonly metricsUsed: MetricsUsed[]
  // added
  expanded?: boolean
  showCancelOption?: boolean
}

export type MetricsUsed = Record<string, string[]>

export interface AssignDevicesModalConfig {
  subscription: SubscriptionResponse
  candidateDevices: Device[] | DMSDeviceMapping[]
  devicesUsed?: string[]
  service: 'impulse' | 'emma' | 'dmsAddonBasic'
}

export type AssignmentTypes = 'assigned' | 'not_assigned' | 'not_emma' | 'virtual'

export type EmmaDevice = {
  assignment: AssignmentTypes
  metric: WidgetMetric
  name: string
  source: string
  type: 'real' | 'virtual'
  endpointId: string
  filteredIn?: boolean
  filteredOut?: boolean
}

export interface SelectSubscriptionModalConfig {
  device: DeviceInfo
  serviceId: ServiceIds
}

export type ServiceIds = 'tsd' | 'ia' | 'emma' | 'dmsAddonBasic' | 'tsd-prologis' | 'chargeRepay' | 'ems'

export type LongServiceIds = 'tsd' | 'impulseanalytics' | 'emma'

export type UserFormData = {
  firstName: string
  lastName: string
  email?: string
  locale?: string[]
}

export type AvailableEndpointMetricsResponse = Record<string, string[]>

export type AnchorElementNames = 'emmaAnchor' | 'impulseAnalyticsAnchor'

export type MacKeyCodes = 'KeyL' | 'KeyR' | 'KeyB' | 'ArrowLeft' | 'ArrowRight'

export type Panels = 'leftPanel' | 'rightPanel' | 'bottomPanel'

export type KeyMapping = { [key in MacKeyCodes]: Panels }

export type CssDefinition = {
  path: string[]
  background: string
  wording: string
  debug?: boolean
  animate?: any // TODO: What is this?
  import?: any // TODO: What is this?
}

export type MobileControls = 'map' | 'search' | 'sort'

export type AcceptableBillingCountryCodes = 'DE' | 'CH' | 'IT' | 'ES' | 'CL'

export type UpdatableDeviceDetails = {
  deviceName: string
  comment: string
  tags: string | string[]
}

export type HttpHeader = {
  Authorization?: string
  'API-Secret'?: string
  'Content-Type': string
  Accept: string
  'Access-Control-Allow-Headers': string
  'X-Security-Assume'?: string
}

export type FirmwareUpdateIcon = 'spinner' | 'check' | 'error' | false

export type Location = {
  lat?: number | string
  lng?: number | string
  address?: string
}

export type DeviceLogsConfig = {
  filteredLogs: Conlog[]
  visibleLogs: Conlog[]
  query: string
  sort: {
    key: 'time' | 'level' | 'tag' | 'message'
    direction: 1 | -1
  }
}

export type OnlineOffline = 'online' | 'offline'

export type globalMetricColourMap = { [key: string]: string }

export interface DeviceInformationResponse {
  device_information: DeviceInformation[]
}

interface DeviceInformation {
  article: string
  business_unit?: string
  category: string
  description: string
  device_type: string
  gateway?: boolean
  id: number
  jws: boolean
  services: ServiceIds[]
}

/*
  Roles coming from IAM. There are only a few predefined roles available at the moment.
  Will be enhanced by new and more detailed roles
 */
export enum OrganisationRole {
  ROLE_VIEWER = 'ROLE_VIEWER',
  ROLE_EDITOR = 'ROLE_EDITOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export interface Organisation {
  organizationId: string
  organizationName: string
  organizationDetails?: OrganisationDetails
  rbacSettings?: {
    defaultAccess: 'allow' | 'deny'
  }
  userRole: OrganisationRole
  expansionState?: ExpansionStates
}

export interface OrganisationDetails {
  organizationId: 'string'
  organizationName: 'string'
  memberCount: number
  organizationCreator: {
    userId: 'string'
    email: 'string'
    firstName: 'string'
    lastName: 'string'
  }
}

export interface OrganisationsResponse {
  data: Organisation[]
}

export interface OrganisationDetailResponse {
  data: OrganisationDetails
}

export interface Member {
  user: {
    email: string
    firstName: string
    lastName: string
    userId: string
    created: string
    expansionState?: ExpansionStates
  }
  userRole: OrganisationRole
}

// TODO can be deleted once it got moved to user management module
export interface Invitation {
  createdBy: string
  createdById: string
  createdTime: string
  id: string
  message: string
  organizationId: string
  organizationName: string
  status: string
  statusChangeTime: string
  securityToken: string
  userEmail: string
  userId: string
  userRole: string
  invitationLink: string
  expansionState?: ExpansionStates
}

export interface OrganisationMembersResponse {
  data: {
    memberCount: number
    members: Member[]
  }
}

export type Trigger = { off: number; on: number; tl_green: number; tl_red: number; tl_warning: number }

export interface ApiResponseV_1_2<T = any> {
  apiVersion: string
  id: string
  context?: string
  method?: string
  data?: T
  error?: any
}

export type OrganisationTabs = 'information' | 'subscriptions' | 'billing' | 'users'

export type ConsumptionDuration = 'day' | 'week' | 'month' | 'year'

export type ConsumptionInputUnit = 'kg' | 'ton' | 'count'

export type ConsumptionOutputUnit = 'kwhkg' | 'kwhton' | 'kwhcount'

export type AllNoneSome = 'all' | 'none' | 'some'

export type AlertNotificationTypes = 'APP' | 'EMAIL'

export type PriorityLevels = 'LOW' | 'MEDIUM' | 'HIGH'

export type AlertPeriods = 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'ANNUALLY'

export type MeasurementAlertPeriods = 'MINUTES' | 'HOURS' | 'DAYS' | 'WEEKS'

export type AlertFrequencies = 'END_OF_PERIOD' | 'DEFAULT_INTERVAL_OF_PERIOD'

export type AlertType = 'ABOVE' | 'BELOW'

export declare interface ProficloudController {
  organisationSwitchedSubscription: Subscription
}

export interface AlertRule {
  activeStatus?: boolean
  id: string
  alertType: AlertType
  checkFrequency?: AlertFrequencies
  metricIds: string[]
  meteringPointIds: string[]
  name: string
  notifications: {
    config: string
    type: 'APP' | 'EMAIL' // None?
  }[]
  period?: AlertPeriods
  rollingWindowValue?: number
  alertRollingWindowPeriod?: MeasurementAlertPeriods
  priorityLevel: PriorityLevels
  threshold: number
  thresholdUnit?: Unit
  receivedAlertCount: number
  totalMessages?: number
  unacknowledgedMessages: number
}

export interface AlertRulesResponse {
  data: AlertRule[]
}

export interface EmmaDeviceResponse {
  endpointId: string
  assignment: 'assigned' | 'not_assigned' | 'not_emma'
  metric: DataSourceUnit
  name: string
  source: 'empro' | 'csv'
  type: 'real' | 'virtual'

  // set by app
  filteredIn?: boolean
  filteredOut?: boolean

  // set by timeseries quries
  tsd?: TSD
}

export interface EmmaEnabledDevicesResponse {
  devices: Record<string, EmmaDeviceResponse>
}

export interface IamUserResponse {
  data: {
    created: string
    email: string
    firstName: string
    lastName: string
    updated: string
    userId: string
  }
}

export interface IamCreateUser {
  firstName: string
  lastName: string
  email: string
  termsAndConditions: 'Accepted' | 'Rejected'
  password: string
  country: string
  invitationToken: string | undefined
  organizationName: string
  locale: 'en-GB'
  redirectUri: string
}

export type NotificatioNCapableServices = 'emma' | 'device-management' | 'user-management' | 'unknown'

export type NotificationPriority = 'info' | 'warning' | 'alert'

export interface IServiceNotification {
  id: string
  triggerTS: string
  responsible: NotificatioNCapableServices
  priority: NotificationPriority
  description: string
}

export interface StreamedDeviceNotification extends IServiceNotification {}

export interface StreamedUserNotification extends IServiceNotification {}

export interface SystemNotification {
  serviceNotification: IServiceNotification
  dismissed: boolean
  received: Date
  persisted?: boolean
}

export interface EmmaAlertMessage extends IServiceNotification {
  acknowledged: boolean
  alertRuleID: string
  alertType: AlertType
  createdAt: string
  periodStart: string
  id: string
  meteringPointId: string
  metricId: string
  orgId: string
  period: AlertPeriods
  priorityLevel: PriorityLevels
  threshold: number
  thresholdUnit: Unit
  value: number
  valueUnit: Unit
  alertRollingWindowPeriod: MeasurementAlertPeriods
  rollingWindowValue: number
  // Added by system
  parentRule?: AlertRule
  expansionState?: ExpansionStates
  queryableName?: string
}

export interface EmmaAlertMessagesResponse {
  data: EmmaAlertMessage[]
  pagination: {
    items: number
    page: number
    totalItems: number
    totalPages: number
  }
}

export type EmmaAlertMessagesOptions = { status?: 'all' | 'acknowledged' | 'unacknowledged'; page?: number; limit?: number }

export interface RBACDefaultAccessResponse {
  data: {
    defaultAccess: 'allow' | 'deny'
  }
}

export type NewMetricTypeLabels = 'CSV' | 'Summation' | 'Average' | 'Device Management Service'

export type NewMetricTypes = 'csv' | 'dms' | 'inbox' | 'external'
export type NewCalculationTypes = 'sum' | 'avg' | 'kpi'
export type AddFromSourceTypes = 'csv' | 'dms'

export interface MetricMapping {
  measurementTypeId: AcceptableMeasurementTypes
  sourceDeviceId: string
  sourceMetricId: string
  inputUnit?: {
    baseUnit: {
      unit: string
      unitType: string
    }
    prefix?: string
    customScale?: number
    altRepr?: string
  }
}

export interface AvailableDMSDevice {
  availableMetricNames: string[]
  id: string
  name: string
}

export interface AvailableDMSDevicesResponse {
  data: AvailableDMSDevice[]
}

export interface DeviceMappingsResponse {
  data: DMSDeviceMapping[]
}

export interface ImportCsvTaskResponse {
  end: string
  fileName: string
  metricId: string
  start: string
  status: string
  ticketId: string
}
export interface ImportCsvResponse {
  tasks: ImportCsvTaskResponse[]
}

export interface MeterToCreateFromDMS {
  metricsMapping: [
    {
      measurementTypeId: AcceptableMeasurementTypes
      sourceDeviceId: string
      sourceMetricId: string
      inputUnit: {
        baseUnit: {
          unit: string
          unitType: string // TODO: define a list of acceptable unit types?
        }
        prefix: UnitPrefix
      }
    },
  ]
  sourceDeviceProviderId: string
  name: string
}

export interface EMMASimulationMeter {
  metric_name: string
  emma_metric_uuid: string
}

export interface EMMASimulationDevice {
  device_uuid: string
  emma_metrics: Array<EMMASimulationMeter>
}

export interface EMMASimulation {
  name: string
  simulation_uuid: string
  created: string
  running: boolean
  organization_uuid: string
  devices: Array<EMMASimulationDevice>
}

export interface CreateEMMASimulation {
  name: string
}

export interface SmardDataDevice {
  name: string
  device_uuid: string
  created: string
  organization_uuid: string
}

export interface CreateSmardDataDevice {
  name: string
}

export interface EditMeterDTO {
  metricsMapping: MetricMapping[]
  name: string
}

// TODO: Change meter to metric
export type FormulaNodeTypes = 'metric' | 'metering_point' | 'number' | 'operator' | 'unit' | 'bracket'

export interface FormulaValidation {
  nodes: { type: FormulaNodeTypes; value: string }[]
}

// TODO: out of date for new formula validation route
export interface FormulaValidationResponse {
  valid: boolean
  error?: {
    id: string
    message: string
    details: any[]
    timestamp: string
  }
  unitType: CompositeUnitType
}

export type MeterTypes = 'simulated' | 'csv' | 'device_database' | 'physical' | 'calculated'

export interface StandardAPIError {
  error: {
    details: any[]
    id: string
    message: string
    timestamp: string
  }
}

export interface FormulaValidationError {
  detail: {
    type: string
    loc: [string | number]
    msg: string
    input: {
      value: string
    }
    url: string
  }[]
}

// These come from the mockup and aren't necessarily final
export type TreeNodeTypes =
  | 'metric'
  | 'air'
  | 'building'
  | 'default'
  | 'device'
  | 'energy'
  | 'energy-multiple'
  | 'heat'
  | 'machine'
  | 'site'
  | 'steam'
  | 'tree'
  | 'water'

export interface Metric {
  id: string
  name: string
  meteringPointId?: string
  measurementType: AcceptableMeasurementTypes
  unit: Unit
  appearance: {
    colour?: string
  }

  // Set by app
  showMenu?: boolean
  combinedUnit?: string
  highlighted?: boolean
}

export interface AggregatedMetric {
  id: string
  measurementType: AcceptableMeasurementTypes | null
  meteringPointId: string
  method: AggregationMethods
  name: string
  unitType: BaseUnitID
  appearance: {
    colour?: string
  }

  // Set by app
  showMenu?: boolean
  highlighted?: boolean
}

export interface MeteringPoint {
  id: string
  name: string
  metrics: Metric[]
  aggregatedMetrics: AggregatedMetric[]
  children: MeteringPoint[]
  parentId?: string
  orderNumber: number
  appearance: {
    type?: TreeNodeTypes // Note: points have no inherent type (yet)
    colour?: string
    expanded?: boolean
    metricsExpanded?: boolean
    accessUsersExpanded?: boolean
  }
  accessUserIds: string[]

  // Set by app
  showMenu?: boolean
  nameEdit?: boolean
  totalMetricCount?: number
  highlighted?: boolean
  allSortedMetrics?: (Metric | AggregatedMetric)[]
}

export interface DropInfo {
  targetId: string | null
  action?: 'inside' | 'before' | 'after' | 'to-inbox'
}

export type FormulaNodeValue =
  | string
  | {
      id: string
      unitType: string
      measurementType?: string
    }
  | {
      prefix: UnitPrefix
      baseUnit: BaseUnitID
    }

export type CalculationNode = { type: FormulaNodeTypes; value: FormulaNodeValue }
export type CalculationTypes = 'FORMULA' | 'SUM' | 'AVG'
export interface Calculation {
  id: string
  name: string
  nodes: CalculationNode[]
  calculationType: CalculationTypes
  unitType: CompositeUnitType
  appearance: {
    // Note: This could actually be any but we only use colour for now
    colour?: string
  }

  // Internally set
  showMenu?: boolean
  highlighted?: boolean
}

export interface DeviceMapping {
  deviceId: string
  deviceMetricName: string
  metric: {
    name: string
    measurementType: AcceptableMeasurementTypes
    unit: Unit
    meteringPointId?: string
  }
}

export interface CreateDeviceMapping {
  deviceId: string
  deviceMetricName: string
  metric?: {
    name: string
    measurementType: AcceptableMeasurementTypes
    unit: Unit
    meteringPointId?: string
  }
  metricId?: string
}

export interface ReplaceDeviceMapping {
  deviceId: string
  deviceMetricName: string
  metricId: string
}

export interface DMSDeviceMapping {
  deviceId: string
  deviceMetricName: string
  id: string
  metricId: string
}

export type AggregationMethods = 'raw' | 'sum' | 'mean' | 'min' | 'max' | 'avg'
export interface QueryDataResponse<AggregationMethods> {
  data: {
    id: string
    start: string
    end: string
    value: AggregationMethods extends 'sum' ? number : { timestamp: string; value: number }[]
  }[]
}

export type NumericaTimeSeries = { timestamp: string; value: number | null }[]

export interface QueryDataResponseGeneral {
  data: {
    id: string
    start: string
    end: string
    value: NumericaTimeSeries | number | null
    unitType: CompositeUnitType
  }[]
  outputUnits: { [key in BaseUnitTypeID]?: Unit }
}

export interface QueryDataResponseRaw {
  data: {
    id: string
    start: string
    end: string
    value: NumericaTimeSeries
    unitType: CompositeUnitType
  }[]
  outputUnits: { [key in BaseUnitTypeID]?: Unit }
}

export interface QueryMetaDataResponseRaw {
  data: {
    id: string
    firstPointAfter: string
    firstPointBefore: string
  }[]
}

export interface QueryDataResponseAggregated {
  data: {
    id: string
    start: string
    end: string
    value: number
    unitType: CompositeUnitType
  }[]
  outputUnits: { [key in BaseUnitTypeID]?: Unit }
}

export interface QueryDataCSVResponse {
  csv: string
}

export interface MetricValueUpdateResponse {
  id: string
  metricId: string
  timestamp: string
  fromValue: number
  toValue: number
  createdAt: string
  createdById: string
}

export interface DataExportJob {
  id: string
  exportName: string
  receiverMail: string[]
  status: string
  createdBy: string
  orgId: string
  preSignedUrl: string
  createdAt: string
  updatedAt: string
}

export interface QueryDataRawResponse {
  data: Record<string, string | Date>[]
  pagination: {
    items: number
    page: number
    totalItems: number
    totalPages: number
  }
}

export interface ChangeEvent {
  id: string
  metricId: string
  createdAt: Date
  createdById: string
  massReplace: boolean
  timestamp: Date | null
  fromValue: string | null
  toValue: string | null
  replaceStart: Date | null
  replaceEnd: Date | null
}

export interface ChangeLogRepsonse {
  data: ChangeEvent[]
  pagination: {
    items: number
    page: number
    totalItems: number
    totalPages: number
  }
}

export interface ChosenTime {
  hours: number
  minutes: number
}

// Note: Not currently able to make this any more specific as we don't have regex in TS yet
export type DateRangeToken = string

export type ValidPeriods = 'D' | 'W' | 'M' | 'Y'

// BEGIN: EMMA Units stuff

export type BaseUnitID = string

export type BaseUnitTypeID = string

export type BaseUnitSymbol =
  | 'W'
  | 'hp'
  | 'J'
  | 'Wh'
  | 'Wm'
  | 'Ws'
  | 'cal'
  | 'hph'
  | '°C'
  | '°F'
  | 'K'
  | 'm³'
  | 'l'
  | 'gal'
  | 'ft³'
  | 'Pa'
  | 'PSI'
  | 'bar'
  | 'atm'
  | 'N/m²'
  | 'm²'
  | 'g'
  | 't'
  | '%'
  | ''
  | 'I'
  | 'V'

export type BaseUnit = {
  unit: BaseUnitID
  unitType: BaseUnitTypeID
  symbol: BaseUnitSymbol
}

export type BaseUnitWithInfo = BaseUnit & {
  nonStandardUnit: boolean
  sensibleInputPrefixes: UnitPrefix[]
  sensibleOutputPrefixes: UnitPrefix[]
}

export type BaseUnitsResponse = BaseUnitWithInfo[]

export interface UnitTypesResponse {
  baseTypes: BaseUnitTypeID[]
  compositeTypes: CompositeUnitType[]
}

export interface UnitTypeDefaultsRepsonse {
  unitType: BaseUnitTypeID
  defaults: UnitTypeDefaults
}

export interface WageInfoRepsonse {
  wageType: Exclude<AcceptableMeasurementTypes, null>
  info: WageTypeInfo
}

export interface CompositeUnitType {
  dividend: {
    count: number
    unitType: BaseUnitTypeID
  }[]

  divisor: {
    count: number
    unitType: BaseUnitTypeID
  }[]
}

export type ReportEnergyTypes = 'electricity'

// Note: These will become the metering point and metric tags, probably deprecated now
export type AcceptableMeasurementTypes =
  | 'electrical-power'
  | 'electrical-energy'
  | 'water-volume'
  | 'gas-volume'
  | 'gas-energy'
  | 'coldthermal-energy'
  | 'hotthermal-energy'
  | 'steamthermal-energy'
  | 'compressed-air'
  | 'pressure'
  | null

// export type TypedQueryableID = { id: string; measurementType: AcceptableMeasurementTypes }

export type UnitPrefix =
  | 'QUECTO'
  | 'RONTO'
  | 'YOCTO'
  | 'ZEPTO'
  | 'ATTO'
  | 'FEMTO'
  | 'PICO'
  | 'NANO'
  | 'MICRO'
  | 'MILLI'
  | 'CENTI'
  | 'DECI'
  | '_'
  | 'DECA'
  | 'HECTO'
  | 'KILO'
  | 'MEGA'
  | 'GIGA'
  | 'TERA'
  | 'PETA'
  | 'EXA'
  | 'ZETTA'
  | 'YOTTA'
  | 'RONNA'
  | 'QUETTA'
export type UnitPrefixSymbol =
  | 'q'
  | 'r'
  | 'y'
  | 'z'
  | 'a'
  | 'f'
  | 'p'
  | 'n'
  | 'µ'
  | 'm'
  | 'c'
  | 'd'
  | ''
  | 'da'
  | 'h'
  | 'k'
  | 'M'
  | 'G'
  | 'T'
  | 'P'
  | 'E'
  | 'Z'
  | 'Y'
  | 'R'
  | 'Q'

export interface UnitTypeDefaults {
  defaultOutputUnit: Unit
  supportedCharts: ChartType[]
  availableBaseUnits: BaseUnit[]
}

export interface WageTypeInfo {
  name: string
  unitType: BaseUnitTypeID
}

export interface Unit {
  baseUnit: BaseUnit
  prefix: UnitPrefix
  customScale?: number
  altRepr?: string
}

export type FormulaOperators = '+' | '-' | '*' | '/'

export type Formula = (
  | {
      type: 'metric'
      value: string
      costConversion?: CostsTypes | null
    }
  | {
      type: 'calculation'
      value: string
    }
  | {
      type: 'operator'
      value: FormulaOperators
    }
  | {
      type: 'metering_point'
      value: { id: string; unitType: BaseUnitTypeID; measurementType?: AcceptableMeasurementTypes }
      costConversion?: CostsTypes | null
    }
)[][]

export interface DataQueryParams {
  dataRequest: {
    start: string[]
    end: string[]
    window: WindowPeriods
    csv?: boolean
    timezone?: string
  }
  formula: Formula
  aggregation: {
    function?: string // TODO
  }
  outputUnits?: {
    [key in BaseUnitTypeID]?: {
      baseUnit: {
        unit: BaseUnitID
        unitType: BaseUnitTypeID
      }
      prefix: UnitPrefix
    }
  }
}

export interface DataExportRequest {
  exportName: string
  receiverMail: string[]
  queryRequest: DataQueryParams
}

// END: EMMA Units stuff

export type WagesTypes =
  | 'electrical-energy'
  | 'coldthermal-energy'
  | 'compressed-air'
  | 'hotthermal-energy'
  | 'steamthermal-energy'
  | 'water-volume'
  | 'gas-energy'
  | 'gas-volume'

export type CostsTypes = 'CARBON_EQUIVALENT' | 'MONETARY' | 'CONSUMPTION'

export interface Rate {
  id?: string
  price: number
  startingAt: string // Date
}

export interface Cost {
  id: string
  wageType: WagesTypes
  costType: CostsTypes
  price: number
  costUnit: Unit
  consumptionUnit: Unit
  rates: Rate[]

  // Added
  visible: boolean
  lable: string
  icon: string
  applyRequired: boolean
}

export type CostsResponse = Cost[]

export type FormulaCreationResponse = {
  res: Calculation
}

export type Features = 'COSTS_EDIT' | 'EXPORT_CSV' | 'RAW_DATA_EDIT'
export type PackageType = 'FULL' | 'STARTER' | 'LEGACY' | 'NONE'
export interface FeatureSetAndLimitsResponse {
  features: Features[]
  limits: {
    METRICS?: number
    ALERTS?: number
    WIDGETS?: number
    LIVE_WIDGETS?: number
    QUERY?: number
  }
  packageType: PackageType
}
export interface DeviceByUuid {
  comment: string
  comment_formatted: string
  con_DeviceType: string
  con_DeviceType_formatted: string
  con_FirmwareVersion_formatted: string
  con_HardwareVersion_formatted: string
  con_SerialNumber_formatted: string
  con_connected: boolean
  con_connected_formatted: string
  con_connected_ts: string
  con_connected_ts_formatted: string
  createdDate_formatted: string
  deviceName: string
  organizationId: string
  organizationMembers: {
    email: string
    firstName: string
    lastName: string
    userId: string
  }[]
  firmwareUpdates: {
    communication_protocol: string
    endpoint_id: string
    firmware_id: string
    firmware_version: string
    timestamp: string
    transaction_id: string
    user: string
    user_id: string
  }[]
  organizationName: string
  tags: string[]
  trafficLightMessage_formatted: string
  uuid: string
  uuid_formatted: string
  virtual: boolean
}
